import { createContext, useReducer, useContext } from "react";
import example from "../sample/example";

const initialYaml = example;

/**
 * Parse the given schema and return a formData object that contains the
 * default values for each property.
 *
 * @param {Object} schema - The schema object to parse
 * @returns {Object} formData - an object containing the default values for
 * each property in the schema
 */
export const parseSchemaDefaults = (schema) => {
  const formData = {};
  Object.keys(schema.properties).forEach((key) => {
    const property = schema.properties[key];
    if (property.default !== undefined) {
      formData[key] = property.default;
    } else if (property.type === "object" && property.properties) {
      formData[key] = parseSchemaDefaults(property);
    }
  });
  return formData;
};

const initialFormData = Array(initialYaml.tabs.length)
  .fill(null)
  .map((_, index) => parseSchemaDefaults(initialYaml.tabs[index].schema));

const initialState = {
  yamlSchema: initialYaml,
  FormData: initialFormData,
};

/**
 * Create an action to update the yamlSchema in the state.
 * @param {object} yamlSchema - The new yamlSchema to update the state with
 * @returns {object} An action with type "UPDATE_YAML_SCHEMA" and the new yamlSchema as payload
 */
export const updateYamlSchema = (yamlSchema) => {
  return { type: "UPDATE_YAML_SCHEMA", payload: yamlSchema };
};

/**
 * Create an action to update the FormData in the state.
 * @param {array} FormData - The new FormData to update the state with
 * @returns {object} An action with type "UPDATE_FORM_DATA" and the new FormData as payload
 */
export const updateFormData = (FormData) => {
  return { type: "UPDATE_FORM_DATA", payload: FormData };
};

/**
 * A reducer that manages the yamlSchema and FormData in the state.
 * @param {object} state - The current state
 * @param {object} action - The action to take
 * @returns {object} The new state
 */
const schemaReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_YAML_SCHEMA":
      return { ...state, yamlSchema: action.payload };
    case "UPDATE_FORM_DATA":
      return { ...state, FormData: action.payload };
    default:
      return state;
  }
};

export const SchemaContext = createContext(initialState);

/**
 * A hook that returns the `tabs` array from the state's `yamlSchema`.
 * The `tabs` array is an array of objects, each containing a `name`, `schema`, and `uiSchema`.
 * The `name` is the name of the tab, the `schema` is the JSON schema for the tab's form, and the `uiSchema` is the ui schema for the tab's form.
 * @returns {array} The `tabs` array from the state's `yamlSchema`.
 */
export const useSchemas = () => {
  const { state } = useContext(SchemaContext);
  if (state.yamlSchema && state.yamlSchema.tabs) {
    return state.yamlSchema.tabs;
  }
};

/**
 * Provides the state and dispatch functions of the SchemaContext to its children.
 * The `state` object contains the current `yamlSchema` and `FormData` of the editor.
 * The `dispatch` function can be used to update the state of the editor.
 *
 * @param {ReactNode} children - The components that should be provided with the state and dispatch functions.
 * @returns {ReactElement} A context provider that wraps the given children.
 */
export const SchemaProvider = ({ children }) => {
  const [state, dispatch] = useReducer(schemaReducer, initialState);

  return (
    <SchemaContext.Provider value={{ state, dispatch }}>
      {children}
    </SchemaContext.Provider>
  );
};
