import React, { useCallback, useState, useContext, useEffect } from "react";
import * as monaco from "monaco-editor";
import Editor, { loader } from "@monaco-editor/react";
import { configureMonacoYaml } from "monaco-yaml";
import YAML from "yaml";
import isEqualWith from "lodash/isEqualWith";
import {
  SchemaContext,
  updateYamlSchema,
  updateFormData,
  useSchemas,
  parseSchemaDefaults,
} from "../context/SchemaContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Toast from "react-bootstrap/Toast";

configureMonacoYaml(monaco, {
  enableSchemaRequest: true,
  schemas: [
    {
      uri: "http://gitlab.com/cs-global/research-and-development/software/portfolio/shared-technology/yaml-form-config-tool/src/schema.json",
      fileMatch: ["main.yaml"],
      schema: {
        $schema: "http://json-schema.org/draft-07/schema#",
        $id: "http://localhost:3000",
        type: "object",
        properties: {
          tabs: {
            type: "array",
            description: "list of form schemas",
            items: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                  description: "name of the form",
                },
                schema: {
                  type: "object",
                  description: "schema of the form",
                },
                uiSchema: {
                  type: "object",
                  description: "ui schema of the form",
                },
              },
              required: ["name", "schema"],
            },
            minItems: 1,
          },
        },
        required: ["tabs"],
      },
    },
  ],
});

global.MonacoEnvironment = {
  getWorker(moduleId, label) {
    switch (label) {
      case "editorWorkerService":
        return new Worker(
          new URL("monaco-editor/esm/vs/editor/editor.worker", import.meta.url)
        );
      case "yaml":
        return new Worker(new URL("monaco-yaml/yaml.worker", import.meta.url));
      default:
        throw new Error(`Unknown label ${label}`);
    }
  },
};

loader.config({ monaco });

loader.init().then(() => {
  console.log("monaco loaded");
});

/* TO DO
    Need to add a format button to the yaml editor so the user can easily format the yaml file
*/
const YamlEditor = ({ code, onChange, setSelectedTab }) => {
  const [LiteralCode, setLiteralCode] = useState(
    YAML.stringify(code, { directives: true })
  );
  const { state, dispatch } = useContext(SchemaContext);
  const [Valid, setValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [FileName, setFileName] = useState("Untitled");
  const [showToast, setShowToast] = useState(false);
  const [edited, setEdited] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (!edited) {
      const reparseFormData = () => {
        const newFormData = Array(state.yamlSchema?.tabs?.length ?? 0)
          .fill(null)
          .map((_, index) =>
            parseSchemaDefaults(state.yamlSchema.tabs[index].schema)
          );
        dispatch(updateFormData(newFormData));
      };
      reparseFormData();
    }
  }, [state.yamlSchema, edited]);

  useEffect(() => {
    setSelectedTab(state.yamlSchema?.tabs[0]?.name);
  }, [uploadedFile, setSelectedTab]);

  const removeInvalidFormData = () => {
    const newFormData = [...state.FormData];
    newFormData.forEach((tab, tabIndex) => {
      const tabProperties = state.yamlSchema.tabs[tabIndex].schema.properties;
      Object.keys(tab).forEach((key) => {
        if (!tabProperties.hasOwnProperty(key)) {
          delete tab[key];
        }
      });
    });
    dispatch(updateFormData(newFormData));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const download = (text, fileName) => {
    setIsFileDownloading(true);
    const blob = new Blob([text], { type: "text/YAML" });

    const a = document.createElement("a");
    if (!fileName.includes(".udf.yaml")) {
      a.download = fileName + ".udf.yaml";
    } else {
      a.download = fileName;
    }
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/YAML", a.download, a.href].join(":");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
      setIsFileDownloading(false);
      setIsModalOpen(false);
      setShowToast(true);
    }, 1500);
  };

  const onCodeChange = useCallback(
    (code) => {
      setEdited(true);
      setLiteralCode(code);
      if (!LiteralCode) {
        setEdited(false);
        return;
      }
      //setLiteralCode(code);
      try {
        const parsedCode = YAML.parse(code);
        setValid(true);
        onChange(parsedCode);
        try {
          removeInvalidFormData();
        } catch (e) {
          console.log("no form data to remove");
          setValid(false);
        }
      } catch (e) {
        setValid(false);
      }
    },
    [setValid, onChange, setLiteralCode, LiteralCode]
  );

  return (
    <Container fluid>
      <Row>
        <input
          type="file"
          id="uploadFile"
          accept="*.yaml"
          style={{
            visibility: "hidden",
            height: "0px",
            width: "0px",
          }}
          onChange={(e) => {
            const reader = new FileReader();
            reader.addEventListener("load", (e) => {
              setLiteralCode(e.target.result);
              setUploadedFile(e.target.result);
            });
            reader.addEventListener("loadend", (e) => {
              onCodeChange(e.target.result);
              setEdited(false);
            });
            reader.readAsText(e.target.files[0]);
          }}
        />
        <span>
          <Button
            variant="warning"
            style={{ marginRight: 15 }}
            onClick={() => document.querySelector("#uploadFile").click()}
          >
            Upload File
          </Button>
          <Button variant="secondary" onClick={openModal}>
            Download File
          </Button>
        </span>
        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Name File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              minLength={1}
              style={{ width: "100%" }}
              value={FileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button
              disabled={isFileDownloading}
              variant="warning"
              onClick={() => download(LiteralCode, FileName)}
            >
              {isFileDownloading ? (
                <Spinner animation="border">
                  <span className="visually-hidden">File Downloading...</span>
                </Spinner>
              ) : (
                "Download"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      <Row>
        <div> {Valid ? "Valid" : "Invalid"} </div>
      </Row>
      <Row>
        <Editor
          height="400px"
          width="100%"
          language="yaml"
          theme="vs-light"
          value={LiteralCode}
          path="main.yaml"
          options={{
            minimap: { enabled: false },
            automaticLayout: true,
          }}
          onChange={onCodeChange}
        />
      </Row>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: "absolute",
          bottom: 20,
          right: 20,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Download Complete</strong>
        </Toast.Header>
        <Toast.Body>File has been downloaded successfully!</Toast.Body>
      </Toast>
    </Container>
  );
};

const Editors = ({ selectedTab, setSelectedTab }) => {
  const { state, dispatch } = useContext(SchemaContext);
  const Schemas = useSchemas();

  useEffect(() => {
    if (
      !Schemas?.find((schema) => schema.name === selectedTab) &&
      Schemas?.length > 0
    ) {
      setSelectedTab(Schemas[0]?.name);
    }
  }, [selectedTab, Schemas, setSelectedTab]);

  const onTabChange = useCallback(
    (key) => {
      setSelectedTab(key);
    },
    [setSelectedTab]
  );

  const onYamlSchemaChange = useCallback(
    (newYamlSchema) => {
      dispatch(updateYamlSchema(newYamlSchema));
    },
    [dispatch]
  );

  const onFormDataChange = useCallback(
    (newFormData, index) => {
      if (!newFormData) {
        const newFormDataArray = [...state.FormData];
        newFormDataArray[index] = {};
        dispatch(updateFormData(newFormDataArray));
        return;
      }
      if (
        !isEqualWith(newFormData, state.FormData, (newValue, oldValue) => {
          // Since this is coming from the editor which uses JSON.stringify to trim undefined values compare the values
          // using JSON.stringify to see if the trimmed formData is the same as the untrimmed state
          // Sometimes passing the trimmed value back into the Form causes the defaults to be improperly assigned
          return oldValue === newValue;
        })
      ) {
        try {
          const newFormDataObject = YAML.parse(newFormData, (key, value) => {
            if (value === null) {
              throw new Error("Value should not be null");
            }
            return value;
          });
          const newFormDataArray = [...state.FormData];
          newFormDataArray[index] = newFormDataObject;
          dispatch(updateFormData(newFormDataArray));
        } catch (e) {
          return;
        }
      }
    },
    [dispatch, state.FormData]
  );

  return (
    <Container fluid>
      <Row>
        <h3>YAML Configuration</h3>
        <YamlEditor
          code={state.yamlSchema}
          onChange={onYamlSchemaChange}
          setSelectedTab={setSelectedTab}
        />
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <h3>UI Schemas</h3>
          {/* TODO:
            Need to refactor this because it is unrully and hard to read.
            probably causing some callback hell as well with how many nested if statements there are
          */}
          {/* UI Schemas Editor */}
          {state.yamlSchema?.hasOwnProperty("tabs") ? (
            Schemas !== undefined && Schemas !== null ? (
              Schemas[0]?.hasOwnProperty("name") ? (
                <Tabs activeKey={selectedTab} onSelect={onTabChange}>
                  {Schemas?.map((schema, index) =>
                    schema?.hasOwnProperty("name") ? (
                      <Tab
                        eventKey={schema?.name}
                        title={schema?.name}
                        key={index}
                      >
                        {schema?.hasOwnProperty("uiSchema") ? (
                          <Editor
                            height="400px"
                            language="yaml"
                            theme="vs-light"
                            value={YAML.stringify(schema.uiSchema)}
                            options={{
                              readOnly: true,
                              minimap: {
                                enabled: false,
                              },
                              automaticLayout: true,
                            }}
                          />
                        ) : (
                          <Editor
                            height="400px"
                            language="yaml"
                            theme="vs-light"
                            value={"Schema missing uiSchema"}
                            options={{
                              readOnly: true,
                              minimap: {
                                enabled: false,
                              },
                            }}
                          />
                        )}
                      </Tab>
                    ) : (
                      <Editor
                        height="400px"
                        language="yaml"
                        theme="vs-light"
                        value={"Schema missing items"}
                        options={{
                          readOnly: true,
                          minimap: { enabled: false },
                        }}
                      />
                    )
                  )}
                </Tabs>
              ) : (
                <Editor
                  height="400px"
                  language="yaml"
                  theme="vs-light"
                  value={"No UI Schemas found"}
                  options={{
                    readOnly: true,
                    minimap: { enabled: false },
                  }}
                />
              )
            ) : (
              <Editor
                height="400px"
                language="yaml"
                theme="vs-light"
                value={"No Schemas found"}
                options={{
                  readOnly: true,
                  minimap: { enabled: false },
                }}
              />
            )
          ) : (
            <Editor
              height="400px"
              language="yaml"
              theme="vs-light"
              value={"No Tabs found"}
              options={{
                readOnly: true,
                minimap: { enabled: false },
              }}
            />
          )}
        </Col>
        {/* Form Data */}
        <Col xs={12} sm={6}>
          <h3>Form Data</h3>
          {Schemas === undefined || Schemas === null ? (
            <Editor
              height="400px"
              language="yaml"
              theme="vs-light"
              value={"No Schemas defined"}
              options={{
                readOnly: true,
                minimap: { enabled: false },
              }}
            />
          ) : !Schemas[0]?.hasOwnProperty("schema") ? (
            <Editor
              height="400px"
              language="yaml"
              theme="vs-light"
              value={"No Schemas to populate forms found"}
              options={{
                readOnly: true,
                minimap: { enabled: false },
              }}
            />
          ) : (
            <Tabs activeKey={selectedTab} onSelect={onTabChange}>
              {Schemas.map((formSchema, index) => {
                return (
                  <Tab
                    eventKey={formSchema?.name}
                    title={formSchema?.name}
                    key={index}
                  >
                    <Editor
                      height="400px"
                      language="yaml"
                      theme="vs-light"
                      value={YAML.stringify(state.FormData[index])}
                      onChange={(e) => onFormDataChange(e, index)}
                      options={{
                        minimap: { enabled: false },
                      }}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Editors;
