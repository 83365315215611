import React from "react";
import Header from "./components/Header";
import Playground from "./components/Playground";
import { SchemaProvider } from "./context/SchemaContext";
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <div className="App">
      <Header title="Yormigol" />
      <SchemaProvider>
        <Playground />
      </SchemaProvider>
    </div>
  );
}

export default App;
