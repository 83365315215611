import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const Header = (props) => {
    return (
        <Container fluid>
            <Row>
                <h1 style={{ textAlign: 'center' }}>
                    <img src="/yormigol.png" width="64" height="64" style={{marginRight: 20}}/>
                    {props.title}
                </h1>
            </Row>
        </Container>
    );
};

export default Header;
