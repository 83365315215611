const example = {
  id: "Example Form",
  version: "1.0.0",
  tabs: [
    {
      name: "Settings",
      schema: {
        type: "object",
        properties: {
          "setting:0": {
            title: "OS",
            type: "string",
            readOnly: true,
            default: "Some OS",
          },
          "setting:2": {
            title: "Station Name",
            type: "string",
            default: "Station Name",
          },
          "setting:3": {
            title: "Pakbus Address",
            type: "number",
            minimum: 1,
            maximum: 4096,
            default: 1,
          },
          "setting:4": {
            title: "Security",
            type: "object",
            properties: {
              "component:Security Level 1": {
                title: "Security Level 1",
                type: "number",
                minimum: 0,
                maximum: 65535,
                default: 0,
              },
              "component:Security Level 2": {
                title: "Security Level 2",
                type: "number",
                minimum: 0,
                maximum: 65535,
                default: 0,
              },
              "component:Security Level 3": {
                title: "Security Level 3",
                type: "number",
                minimum: 0,
                maximum: 65535,
                default: 0,
              },
            },
          },
          "setting:5": {
            title: "isRouter",
            type: "boolean",
            default: false,
          },
        },
      },
      uiSchema: {
        "ui:order": [
          "setting:5",
          "setting:0",
          "setting:2",
          "setting:4",
          "setting:3",
        ],
        "ui:style": {
          margin: "5px",
        },
        "setting:0": {
          "ui:style": {
            color: "black",
          },
        },
        "setting:2": {
          "ui:style": {
            color: "red",
          },
        },
        "setting:3": {
          "ui:style": {
            color: "blue",
          },
        },
        "setting:4": {
          "ui:style": {
            color: "green",
          },
        },
        "setting:5": {
          "ui:style": {
            color: "purple",
          },
        },
      },
    },
    {
      name: "Constants",
      schema: {
        type: "object",
        properties: {
          "const:const table:sensor height": {
            title: "Sensor Height",
            type: "number",
            minimum: 0,
            default: 0,
          },
          "const:const table:multipleOf constant": {
            title: "MultipleOf constant",
            type: "number",
            multipleOf: 2,
          },
          "const:const table:string constant": {
            title: "String constant",
            type: "string",
            default: "string constant",
          },
          "const:const table:boolean constant": {
            title: "Boolean constant",
            type: "boolean",
            default: false,
          },
          "const:const table:ApplyAndRestart": {
            title: "ApplyAndRestart",
            type: "boolean",
            default: false,
          },
        },
      },
      uiSchema: {
        "const:const table:ApplyAndRestart": {
          "ui:style": {
            color: "red",
          },
        },
      },
    },
    {
      name: "Variables",
      schema: {
        type: "object",
        properties: {
          "variable:Status:Messages": {
            title: "Messages",
            type: "string",
          },
          "variable:Status:PortStatus:0": {
            title: "Port Status 1",
            type: "boolean",
          },
          "variable:Status:WatchdogErrors": {
            title: "Watchdog Errors",
            type: "number",
            default: 0,
          },
        },
      },
      uiSchema: {
        "variable:Status:Messages": {
          "ui:style": {
            fontSize: "20px",
          },
        },
      },
    },
  ],
};

export default example;
