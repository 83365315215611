import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  SchemaContext,
  updateFormData,
  useSchemas,
} from "../context/SchemaContext";
import Editors from "./Editors";
import ErrorBoundary from "./ErrorBoundary";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import example from "../sample/example";

const Playground = () => {
  const errorBoundaryRef = useRef(null);
  const { state, dispatch } = React.useContext(SchemaContext);
  const Schemas = useSchemas();
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    setSelectedTab(Schemas[0]?.name);
  }, []);

  useEffect(() => {
    if (errorBoundaryRef.current !== null) {
      errorBoundaryRef.current.resetErrorBoundary();
    }
  }, [state.yamlSchema]);

  const onFormDataChange = useCallback(
    (newFormData, index) => {
      const newFormDataArray = [...state.FormData];
      newFormDataArray[index] = newFormData.formData;
      dispatch(updateFormData(newFormDataArray));
    },
    [dispatch, state.FormData]
  );

  const onFormDataSubmit = ({ formData }) => {
    console.log("submitted formData", formData);
    console.log("submit event");
    window.alert("Form submitted");
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={7} style={{ backgroundColor: "lightgray" }}>
          <h2 style={{ textAlign: "center" }}>Configuration</h2>
          <Editors selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </Col>
        <Col xs={12} sm={5} style={{ backgroundColor: "gold" }}>
          <h2 style={{ textAlign: "center" }}>Form Preview</h2>
          {Schemas === undefined || Schemas === null ? (
            <p>No Form to be had</p>
          ) : !Schemas[0]?.hasOwnProperty("schema") ||
            Schemas[0]?.schema === null ? (
            <p>No Form to Render</p>
          ) : (
            <Tabs activeKey={selectedTab} onSelect={setSelectedTab}>
              {Schemas.map((formSchema, index) => (
                <Tab
                  key={index}
                  eventKey={formSchema.name}
                  title={formSchema.name}
                >
                  <ErrorBoundary ref={errorBoundaryRef}>
                    <Form
                      schema={formSchema.schema}
                      uiSchema={formSchema.uiSchema}
                      formData={state.FormData[index] ?? {}}
                      onChange={(e) => onFormDataChange(e, index)}
                      onSubmit={onFormDataSubmit}
                      validator={validator}
                      liveValidate={true}
                    />
                  </ErrorBoundary>
                </Tab>
              ))}
            </Tabs>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Playground;
